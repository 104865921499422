<template>
  <div class="c-app flex-row align-items-center" style="background-image: url('/img/bg.png')">
    <CContainer>
      <CRow class="justify-content-center">
        <CCol md="6">
          <CCardGroup>
            <CCard class="p-4">
              <CCardBody>
                <CForm @submit.prevent="login">
                  <h1 class="text-center">Login</h1>
                  <p class="text-muted text-center">Sign-in to your account</p>
                  <CInput
                    placeholder="Username"
                    v-model="username"
                  >
                    <template #prepend-content><CIcon name="cil-user"/></template>
                  </CInput>
                  <CInput
                    placeholder="Password"
                    type="password"
                    autocomplete="curent-password"
                    v-model="password"
                  >
                    <template #prepend-content><CIcon name="cil-lock-locked"/></template>
                  </CInput>
                  {{message}}
                  <CRow>
                    <CCol col="6" class="text-left">
                      <CButton color="primary" class="px-4" type="submit">Login</CButton>
                    </CCol>
                    <CCol col="6" class="text-right">
                      <!-- <CButton color="link" class="px-0">Forgot password?</CButton> -->
                      <CButton color="link" class="" @click="emailModal = true">Forgot Password?</CButton>
                    </CCol>
                  </CRow> 
                </CForm>
              </CCardBody>
            </CCard>
  
          </CCardGroup>
        </CCol>
      </CRow>
    </CContainer>
    <CModal :title="'Reset Password'" :show.sync="newPasswordModal" color="info" >
		<form @submit.prevent="submitNewPassword"> 
			<CRow>

				<CCol lg="12">
					<label>
						New Password
						<span class="text-danger">*</span>
					</label>
					<CInput 
						placeholder="New password"
						v-model="new_password"
						required="true"
						type="password"
					/>
				</CCol> 
			</CRow>

			<CRow>
				<CCol lg="12">
					<label>
						Confirm Password
						<span class="text-danger">*</span>
					</label>
					<CInput 
						placeholder="Confirm password"
						v-model="confirm_password"
						required="true"
						type="password"
					/>
				</CCol> 
			</CRow>   
			<span class="text-danger">{{message_reset_password}}</span>
			<div slot="footer" class="w-100">
				<CButton style="border-radius: .2rem; color: white;" color="dark" class="ml-1 mr-1 float-right" @click="newPasswordModal = false">
				Cancel
				</CButton>
				<CButton type="submit" style="border-radius: .2rem; color: white;" color="info" class="ml-1 mr-1 float-right">
				Save
				</CButton> 
			</div>

		</form>
		<div slot="footer" class="w-100"></div>
	</CModal>
    <CModal :title="'Forgot Password'" :show.sync="emailModal" color="info" >
		<form @submit.prevent="submitEmail"> 
			<CRow>

				<CCol lg="12">
					<label>
						Email Address
						<span class="text-danger">*</span>
					</label>
					<CInput 
						placeholder="Enter email"
						v-model="password_email"
						required="true"
						type="email"
					/>
				</CCol> 
			</CRow>   
			<div slot="footer" class="w-100">
        
				<CButton style="border-radius: .2rem; color: white;" color="dark" class="ml-1 mr-1 float-right" @click="emailModal = false">
				Cancel
				</CButton>
				<CButton type="submit" style="border-radius: .2rem; color: white;" color="info" class="ml-1 mr-1 float-right">
        Send Password Reset Link
				</CButton>  
			</div>

		</form>
		<div slot="footer" class="w-100"></div>
	</CModal>
  </div>
</template>

<script>
  import axios from 'axios';
  import config from '../config.js';
  import Swal from 'sweetalert2';
  import nav from '../../containers/_nav.js';

export default {
  name: 'Login',
  data(){
    return{
      username: "",
      password: "",
      message: "",
      new_password: "",
      confirm_password: "",
      newPasswordModal: false,
      emailModal: false,
      password_email: "",
      reset_code: null,
      message_reset_password: ""
    }
  },
  mounted(){


    const sideMenu = config.menu;

          console.log(sideMenu)
    window.localStorage.removeItem('user_data');
    window.localStorage.removeItem('jwt');
    this.reset_code = this.$route.query.reset_code
    if(this.reset_code){
    	this.newPasswordModal = true;
    	window.history.replaceState(null, null, window.location.href.split("?")[0]);
    }
  },
  methods: {
    login(){
      this.message = ""
      axios.post(config.api_path+"/auth/authenticate/login", {
        username: this.username,
        password: this.password
      })
      .then((response) =>{

        if(response.data.status == 'success'){
          window.localStorage.setItem('user_data', JSON.stringify(response.data.data));
          window.localStorage.setItem('jwt', response.data.jwt);

          const sideMenu = [...config.menu_permission];
          let url = '';

          for (const iterator of sideMenu) {
              if(iterator?.permission) {
                if(config.getPermission(iterator.permission).allowCrud) {
                  url = iterator.to;
                  break;
                }
              }
          }

          if(!url) url = '/home';

          this.$router.push(url, ()=>{
            setTimeout(()=>{
              window.location.reload();
            }, 1000)
          });


          
        }
        else{
         if(response.data.message == 'account inactive'){
          Swal.fire({
            title: 'Warning!',
            text: "Account is inactive",
            icon: 'error', 
          })
         }
         else{
          Swal.fire({
            title: 'Warning!',
            text: "Invalid username or password.",
            icon: 'error', 
          })
         }
        }
      })
    },
    submitNewPassword(){
    	this.message_reset_password = "";
    	if(this.confirm_password != this.new_password){
    		this.message_reset_password = "Password doesn't match.";
    		return;
    	}
		axios.post(config.api_path+"/authenticate/reset_password", {
			forgot_password_code: this.reset_code,
			password: this.new_password
		})
		.then(response=>{
			this.newPasswordModal = false
			Swal.fire({
				title: 'Success!',
				text: "Password successfully updated.",
				icon: 'success', 
			})

		})
    },
    submitEmail(){
    	axios.post(config.api_path+"/authenticate/request_password", {
			email: this.password_email, 
		})
		.then(response=>{
			Swal.fire({
				title: 'Success!',
				text: "Please check your email for instructions.",
				icon: 'success', 
			})
			this.emailModal = false
		})
    }
  }
}
</script>
